import * as React from 'react'

export const AgeVerificationModal = ({ onClick }: { onClick: () => void }) => (
  <div className="relative z-50" aria-labelledby="age-verification" role="dialog" aria-modal="true">
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        {/* Modal panel. */}
        <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all px-4 pb-4 my-8 w-full max-w-lg">
          <div className="bg-white px-4 pt-5">
            <span className="text-3xl text-gray-900" id="age-verification">
              ARE YOU 21 OR OLDER?
            </span>
            <div className="mt-2 font-secondary">
              <p>
                We know it&apos;s rude to ask, but we&apos;re a licensed, bonded winery. You must be
                over 21 years of age to enter.
              </p>
            </div>
          </div>
          <div className="py-3">
            <button
              className={`
                  w-full justify-center rounded-md border border-transparent bg-dark-gray px-4 py-2 text-white
                 hover:bg-white hover:text-dark-gray hover:border-dark-gray transition duration-200 outline-none focus:outline-none
                `}
              onClick={onClick}
              type="button"
            >
              Yes, I am 21 years of age or older.
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)
