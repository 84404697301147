import * as React from 'react'

import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

export default ({ element }) => (
  // TODO: Create component resolver and link resolver.
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: 'cellar-front-dev',
        componentResolver: undefined,
        linkResolver: doc => (doc.type === 'sw_verbose_content_page' ? `/${doc.uid}` : '/'),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)
