import * as React from 'react'

import type { GatsbyBrowser } from 'gatsby'

import Layout from './src/components/layout'
import wrapWithProvider from './wrap-with-provider'

import './src/styles/global.css'

/**
 * Wrap root element with provider.
 */
export const wrapRootElement = wrapWithProvider

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <Layout>{element}</Layout>
)
